<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('lead.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <base-detail-action-button
      :permissions="permissions"
      :basePath="basePath"
      :id="detail.uuid"
      v-if="detail.uuid"
      @deleteAction="deleteAction"
    >
    </base-detail-action-button>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.createdAt') }}
      </v-col>
      <v-col cols="6">
        {{
          detail.created_at != null ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : ''
        }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"> ID </v-col>
      <v-col cols="6">
        {{ detail.id }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.uuid') }}
      </v-col>
      <v-col cols="6">
        {{ detail.uuid }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.catalogAgentUuid') }}
      </v-col>
      <v-col cols="6">
        {{ detail.catalog_agent != null ? detail.catalog_agent.data.uuid : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.source') }}
      </v-col>
      <v-col cols="6">
        {{ detail.source }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.actorName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.actor != null ? detail.actor.data.name : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.actorEmail') }}
      </v-col>
      <v-col cols="6">
        {{ detail.actor != null ? detail.actor.data.email : '' }}
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    async deleteAction(id) {
      try {
        await this.$store.dispatch('lead/delete', id);
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('lead.delete.successMsg'),
          'success',
        );
        this.$router.back();
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
